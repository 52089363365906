import React from "react";

export const Team = (props) => {
  return (
    <div id="team" className="text-center">
      <div className="container">
        <div className="col-md-8 col-md-offset-2 section-title">
          <p>{" "}</p>
          <p>CONHEÇA NOSSA</p>
          <h2>Equipe</h2>
          <p>
            Nossa equipe é qualificada, com profissionais especializados em diversas áreas, comprometidos em oferecer tratamentos modernos e eficazes.
          </p>
        </div>
        <div id="row" >
          {props.data
            ? props.data.map((d, i) => (
                <div key={`${d.name}-${i}`} className="col-md-4 col-sm-6 team">
                  <div className="thumbnail">
                    {" "}
                    <img src={d.img} alt="..." className="team-img" />
                    <div className="caption">
                      <h4>{d.name}</h4>
                      <p>{d.job}</p>
                    </div>
                  </div>
                </div>
              ))
            : "loading"}
        </div>
        <div className="col-md-8 col-md-offset-2 portfolio-btn">
          <a href="https://api.whatsapp.com/send?phone=5521980366885&text=Ol%C3%A1,%20gostaria%20de%20agendar%20uma%20consulta." className="btn btn-custom btn-lg page-scroll" target="_blank" rel="noreferrer">
          Agendar Consulta <i className="fa fa-whatsapp"></i>
          </a>{" "}
        </div> 
      </div>
    </div>
  );
};
