import React from "react";
import Carousel from "react-material-ui-carousel"

export const About = (props) => {
  return (
    <div id="about">
      <div className="container">
        <div className="row">
          <div className="col-xs-12 col-md-6">

            <Carousel>
              {props.data
                ? props.data.map((d, i) => (
                  <img className="img-responsive" key={`carousel-${i}`} src={d.image} alt="" />
                  ))
                : "loading"}
            </Carousel>
            
          </div>
          <div className="col-xs-12 col-md-6">
            <div className="about-text">
              <p>{" "}</p><p>NOSSO ESPAÇO</p>
              <h2>A Clínica</h2>
              <p>Os procedimentos da clínica são feitos com segurança e atenção especial nos detalhes, buscando sempre resultados equilibrados para proporcionar satisfação aos nossos pacientes. Cada paciente é único, e por isso o atendimento é sempre voltado para as particularidades daquela pessoa.</p> 
              <p>O atendimento é feito em sala individual, climatizada, num ambiente de tranquilidade e bom gosto. Venha fazer uma visita e descubra como podemos ajudar a realçar sua beleza, incrementar sua autoestima e melhorar sua saúde.</p>              
              <a href="https://api.whatsapp.com/send?phone=5521980366885&text=Ol%C3%A1,%20gostaria%20de%20agendar%20uma%20consulta." className="btn btn-custom btn-lg page-scroll" target="_blank" rel="noreferrer">
                Agendar Consulta <i className="fa fa-whatsapp"></i>
              </a>{" "}

            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
