import React from "react";


export const Contact = (props) => {

  return (
    <div>
      <div id="contact">
        <div className="container">
          <div className="col-md-8">
            <div className="row">
              <div className="section-title">
                <h2>Fale Conosco</h2>
              </div>
             
              <div className="google-map-code">
              <div className="map-responsive">
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3673.5836784501416!2d-43.17968672451803!3d-22.965554679213813!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x9bd555a80e75bd%3A0x20a8a8c15cc8cea8!2sAv.%20Nossa%20Sra.%20de%20Copacabana%2C%20195%20-%20Copacabana%2C%20Rio%20de%20Janeiro%20-%20RJ%2C%2022020-002!5e0!3m2!1spt-BR!2sbr!4v1724358462092!5m2!1spt-BR!2sbr"
                  width="600"
                  height="450"
                  frameBorder="0"
                  style={{ border: 0 }}
                  allowFullScreen=""
                  aria-hidden="false"
                  tabIndex="0"
                  title="map"
                />
              </div>
              </div>
                     
             
             
             
             
            </div>
          </div>
          <div className="col-md-3 col-md-offset-1 contact-info">
            <div className="contact-item">
              <img src="img/mgm_branco.png" className="img-responsive" alt="" />{" "}
              <h3>Clinica MGM - Saúde e Estética Avançada</h3>
              <p>
                <span>
                  <i className="fa fa-map-marker"></i> Endereço
                </span>
                Av. Nossa Senhora de Copacabana, 195, Copacabana, Rio de Janeiro - RJ
              </p>
            </div>
            <div className="contact-item">
              <p>
                <span>
                  <i className="fa fa-phone"></i> Whatsapp
                </span>{" "}
                <a href="https://api.whatsapp.com/send?phone=5521980366885&text=Ol%C3%A1,%20gostaria%20de%20agendar%20uma%20consulta." target="_blank" rel="noreferrer">+55 21 98036-6885</a>
              </p>
            </div>
            <div className="contact-item">
              <p>
                <span>
                  <i className="fa fa-instagram"></i> Instagram
                </span>{" "}
                <a href="https://www.instagram.com/clinica.mgm" target="_blank" rel="noreferrer">@clinica.mgm</a>
              </p>
            </div>
          </div>

        </div>
      </div>
      <div id="footer">
        <div className="container text-center">
          <p>
            &copy; 2024 Clínica MGM - Saúde e Estética Avançada. Design by{" "}
            <a href="http://metadox.com.br/" target="_blank" rel="noreferrer">
              Metadox
            </a>
          </p>
        </div>
      </div>
    </div>
  );
};
