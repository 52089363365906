import React from "react";




export const Image = ({ title, image, description }) => {
  
  return (
    <div className="portfolio-item">
      <div className="hover-bg">
        {" "}        
          <div className="hover-text">
            <h4>{description}</h4>
          </div>
          <img src={image} className="img-responsive" alt={title} />{" "}
      </div>      
    </div>          
  );
};
