import { Image } from "./image";
import React from "react";

export const Gallery = (props) => {
  return (
    <div id="portfolio" className="text-center">
      <div className="container">
        <div className="section-title">
          <p>CONFIRA AQUI NOSSOS</p>
          <h2>Procedimentos Estéticos</h2>  
          <p>
            Veja nosso portfólio de resultados no <a href="https://www.instagram.com/clinica.mgm" target="_blank" rel="noreferrer">Instagram  <i className="fa fa-instagram"></i></a>
          </p>  
        </div>     

        <div className="row">
          <div className="portfolio-items">
            {props.data
              ? props.data.map((d, i) => (
                  <div
                    key={`${d.title}-${i}`}
                    className="col-sm-6 col-md-4 col-lg-4"
                  >
                    <Image
                      title={d.title}
                      image={d.image}
                      description={d.description}
                    />
                  </div>
                ))
              : "Loading..."}
          </div>
        </div>        

        <div className="portfolio-btn">
          <a href="https://api.whatsapp.com/send?phone=5521980366885&text=Ol%C3%A1,%20gostaria%20de%20agendar%20uma%20consulta." className="btn btn-custom btn-lg page-scroll" target="_blank" rel="noreferrer">
          Agendar Consulta <i className="fa fa-whatsapp"></i>
          </a>{" "}
        </div>        
        
      </div>
    </div>
  );
};
