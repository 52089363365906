import React from "react";

export const Header = (props) => {
  return (
    <header id="header">
      <div className="intro">
        <img src="img/intro-bg.jpg" alt="" height="auto" width="100%"/>
        <div className="overlay">
          <div className="container">
            <div className="row">
              <div className="col-md-8 col-md-offset-2 intro-text">
                
                <p>O autocuidado é uma forma de realçar o que há de mais belo em você.</p>
                <a href="https://api.whatsapp.com/send?phone=5521980366885&text=Ol%C3%A1,%20gostaria%20de%20agendar%20uma%20consulta." className="btn btn-custom btn-lg page-scroll" target="_blank" rel="noreferrer">
                  Agendar Consulta <i className="fa fa-whatsapp"></i>
                </a>{" "}
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};


